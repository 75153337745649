<template>
  <div class="new-home">
    <div class="start-type flex">
      <div class="flexcenter" :class="{ startactbg: item.id == startTypeId }" v-for="item in startList" :key="item.id"
        @click="handlerType(item.id)">
        {{ item.name }}（{{ item.planetCount }}）
      </div>
    </div>
    <div class="h5-con" v-if="showList">
      <div class="cont-top">
        <!-- <swiper :slides-per-view="1" :loop="true" :autoplay="{ delay: 3000 }">
          <swiper-slide>
            <div class="banner-daobet" @click="handlerLink()">
              <img src="../../public/images/daobetBanner.jpg" alt="" />
            </div>
          </swiper-slide>
        </swiper> -->

        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="banner-daobet" @click="handlerLink()">
                <img src="../../public/images/daobet-banner0.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="banner-daobet" @click="handlerLink()">
                <img src="../../public/images/daobet-banner1.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="banner-daobet" @click="handlerLink()">
                <img src="../../public/images/daobet-banner2.png" alt="" />
              </div>
            </div>
          </div>
        </div>  
       
        <!-- <div class="con-assets" @click="$router.push('/asset')">
          <div class="assets-name flex">
            <img src="../../public/images/new/assetsicon.png" alt="" />{{
              $t("new.desc6")
            }}
          </div>
          <p>${{ totalsBalance }}</p>
        </div> -->

        <div class="home-notice flex" v-if="notice.id != undefined">
          <img src="../../public/images/new/noticeicon.png" alt="" />
          <div class="ellipse">{{ notice.title }}</div>
        </div>
        <div class="home-select flex">
          <div class="select-info">
            <div class="name">{{ $t("node.desc70") }}</div>
            <p>
              <el-select v-model="type" :placeholder="$t('planet.desc1')">
                <el-option :value="0" :label="$t('record.desc14')"></el-option>
                <el-option :value="item.id" :label="item.name" v-for="item in startList" :key="item.id"></el-option>
              </el-select>
            </p>
          </div>
          <div class="select-info">
            <div class="name">{{ $t("node.desc8") }}</div>
            <p>
              <input type="tel" v-model="track" oninput="value=value.replace(/[^\d]/g,'')"
                :placeholder="$t('home.desc55')" />
              <input type="tel" v-model="no" oninput="value=value.replace(/[^\d]/g,'')"
                :placeholder="$t('home.desc56')" />
            </p>
          </div>
          <div class="select-ttn flexcenter" @click="handlerSearch">
            {{ $t("asset.desc59") }}
          </div>
        </div>
      </div>
      <div class="home-start" v-if="showStart">
        <div class="start-list flex" v-for="(item, index) in startList" :key="item.id" @click="handlerType1(item.id)">
          <img :src="item.icon" alt="" />
          <div class="list-info">
            <div class="info-name">
              {{ item.name }}（{{ item.planetCount }}）
            </div>
            <div class="info-desc">
              {{ $t("node.desc67") }} <span>{{ item.allowedCount }}</span>
            </div>
            <div class="info-desc">
              {{ $t("node.desc68") }} <span v-if="index == 0">600</span><span v-else>{{ item.namedCount }}</span>
            </div>
            <div class="info-desc">
              {{ $t("node.desc69") }} <span v-if="index == 0">0</span><span v-else>{{ item.unAllowedCount }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="home-list" v-else>
        <div class="list-info flex" v-for="item in list" :key="item.id">
          <div class="img-star">
            <star-img :sphereImg="item.icon" :starWidth="160" :starHeight="160" />
          </div>
          <div class="info">
            <div class="info-name">{{ item.typeName }}</div>
            <div class="info-price flex">
              <div class="price">
                <div>{{ item.price }} {{ item.payCoinName }}</div>
                <p>{{ $t("node.desc60") }}</p>
              </div>
              <div class="price">
                <div>{{ item.track }} - {{ item.no }}</div>
                <p>{{ $t("node.desc8") }}</p>
              </div>
              <div class="price">
                <div v-if="item.status == 0">{{ $t("planet.desc67") }}</div>
                <div v-if="item.status == 1">{{ $t("planet.desc68") }}</div>
                <div v-if="item.status == 2">{{ $t("planet.desc69") }}</div>
                <p>{{ $t("planet.desc2") }}</p>
              </div>
              <div class="price">
                <div>{{ item.galacticName }}</div>
                <p>{{ $t("node.desc71") }}</p>
              </div>
            </div>
            <div class="planet-btn" @click="handlerInfo(item)" v-if="item.status == 2">
              {{ $t("node.desc11") }}
            </div>
            <div class="planet-btn" v-else @click="handlerInfo(item)">
              {{ $t("planet.desc13") }}
            </div>
          </div>
        </div>
        <div class="no-data" v-if="showNull">
          <img src="../../public/images/no-data.png" alt="" />
          <span>{{ $t("record.desc13") }}</span>
        </div>
      </div>
    </div>
    <Home :startTypeId="startTypeId" v-if="showInfo" :showActivity="showActivity" :track="track" :no="no"
      @activityBtn="handlerActivity" @renderEnd="handleRenderEnd" />
    <div id="footer">
      <glFooter :act="0" />
    </div>
    <h5Top />
    <nodeWrappar v-if="showDetail" :nodeId="nodeId" :level="level" @handlerClose="handlerClose" />
   
    <!-- <el-dialog v-model="showSuccess" :append-to-body="true">
      <div class="modal-con1">
        <div class="modal-title">{{ $t("asset.desc77") }}</div>
        <div class="modal-list">
          <div class="list-info flex">
            <div>{{ $t("market.desc54",{n1:'DU'}) }}</div>
            <p>
              <span>{{ profit.landInComeDu || 0 }}</span>
              <span class="speed flex" v-if="profit.landIncomeDuActivity !=0"><img src="../../public/images/new/speedicon.png" alt="" />
                +{{ common.cutXiaoNum1(profit.landIncomeDuActivity) || 0 }} DU ({{ $t('market.desc206') }})</span>
            </p>
          </div>
          <div class="list-info flex">
            <div>{{ $t("market.desc55") }}</div>
            <p>
              <span>{{ profit.landInComeRc || 0 }}</span>
              <span class="speed flex" v-if="profit.landIncomeRcActivity != 0">
              <img src="../../public/images/new/speedicon.png" alt="" />
              +{{ common.cutXiaoNum1(profit.landIncomeRcActivity) || 0 }} RC ({{ $t('market.desc206') }})
            </span>
            </p>
          </div>
          <div class="list-info flex">
            <div>{{ $t("market.desc57",{n1:'DU'}) }}</div>
            <p>{{ profit.landTaxationDu || 0 }}</p>
          </div>
          <div class="list-info flex">
            <div>{{ $t("market.desc56") }}</div>
            <p>{{ profit.algebraRewardRc || 0 }}</p>
          </div>
          <div class="list-info flex">
            <div>{{ $t("market.desc136") }}</div>
            <p>{{ profit.recommendDu || 0 }}</p>
          </div>
          <div class="list-info flex">
            <div>{{ $t("market.desc207") }} (DU)</div>
            <p>{{ profit.planetDU || 0 }}</p>
          </div>
          <div class="list-info flex">
            <div>{{ $t("market.desc207") }} (RC)</div>
            <p>{{ profit.planetRC || 0 }}</p>
          </div>
          <div class="list-info flex">
            <div class="flex">
              {{ $t("assetbag.desc136") }}(DP)<el-tooltip class="box-item" raw-content effect="dark"
                :content="$t('asset.desc138')" placement="bottom">
                <img src="../../public/images/new/tips.png" alt="" class="img1" />
              </el-tooltip>
            </div>
            <p>{{ profit.integralDp || 0 }}</p>
          </div>
        </div>
        <div class="modal-bgbtn" @click="handlerRevice" v-loading="loading1">
          {{ $t("market.desc135") }}
        </div>
        <div class="modal-tips" v-html="$t('market.desc129')"></div>
      </div>
    </el-dialog> -->

    <PageLoading v-model:show="showLoading" />
    <IntegralUseToast v-if="showInte" />
    <!-- 公告弹窗 -->
    <van-popup v-model:show="showNotice" :close-on-click-overlay="false">
      <div class="notice-con">
        <img src="../../public/images/new/closeicon2.png" alt="" class="close" @click="handlerCloseNotice" />
        <div class="notice-name">{{ $t("notice.desc1") }}</div>
        <div class="notice-title">{{ noticeInfo.title }}：</div>
        <div class="notice-desc" v-html="noticeInfo.content"></div>
        <div class="notice-time">{{ noticeInfo.datetime }}</div>
        <div class="notice-btn" @click="handlerInfoNotice">
          {{ $t("notice.desc2") }}
        </div>
      </div>
    </van-popup>
    <!--邀请弹窗 -->
    <!-- <van-popup v-model:show="showBindInvited" :close-on-click-overlay="false">
      <div class="notice-con">
        <img src="../../public/images/new/closeicon2.png" alt="" class="close" @click="showBindInvited=false" />
        <div class="notice-name">{{ $t("notice.desc1") }}</div>
        <div class="notice-title">{{ noticeInfo.title }}：</div>
        <div class="notice-desc" v-html="noticeInfo.content"></div>
        <div class="notice-time">{{ noticeInfo.datetime }}</div>
        <div class="notice-btn" @click="handlerInfoNotice">
          {{ $t("invite.desc106") }}
        </div>
      </div>
    </van-popup> -->
    <!-- 盲盒弹窗 -->
    <!-- <van-popup v-model:show="showBilnd">
      <div class="blind-con">
        <div class="blind-logo flex">
          <img src="../../public/images/logo.png" alt="" class="img1" />
          <img src="../../public/images/new/closeicon3.png" alt="" class="img2" @click="showBilnd = false" />
        </div>
        <div class="blind-tips">{{ $t('blind.desc5') }}</div>
        <div class="blind-img">
          <img src="../../public/images/bilnd/gift2.gif" alt="" />
        </div>
        <div class="blind-title" v-html="$t('blind.desc6', { n1: drawCount })"></div>
        <div class="blind-btn flexcenter" @click="handlerBlind">{{ $t('blind.desc7') }}</div>
      </div>
    </van-popup> -->
    <!-- 确认迁移弹窗 -->
    <!-- <van-popup show="true" :close-on-click-overlay="false">
      <div class="showTranfer">
        <img src="../../public/images/new/closeicon2.png" alt="" class="close" @click="handlerCloseShowTransferTip(0)" />
        <div class="notice-name">{{ $t('transferAccount.desc23') }}</div>
        <div class="notice-desc">{{ $t('transferAccount.desc19') }}</div>
        <div class="notice-desc">{{ $t('transferAccount.desc24') }}</div>
        <div class="notice-desc">{{ $t('transferAccount.desc25') }}</div>
        <div class="notice-flx-btn">
          <div class="notice-btn notice-btn-cancel"  @click="handlerCloseShowTransferTip(0)">
            {{ $t('transferAccount.desc18') }}
          </div>
          <div class="notice-btn"  @click="handlerCloseShowTransferTip(1)">
            {{ $t('transferAccount.desc17') }}
          </div>
        </div>
      </div>
    </van-popup> -->
  </div>
</template>

<script>
import Home from "../home/index.vue";
import h5Top from "@/componets/h5top.vue";
import glFooter from "@/componets/footer.vue";
import nodeWrappar from "@/componets/nodeInfo.vue";
import starImg from "@/componets/starImg.vue";
import bus from "@/utils/bus";
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'
import { init } from "zrender";


export default {
  components: {
    Home,
    h5Top,
    glFooter,
    nodeWrappar,
    starImg,
  },
  data() {
    return {
      showBilnd: false,
      showSuccess: true,
      showNotice: false,
      // showBindInvited:true,
      showDetail: false,
      showInte: false,
      track: "",
      no: "",
      startTypeId: "",
      startList: [],
      showInfo: false,
      totalsBalance: 0,
      type: 0,
      showList: false,
      notice: {},
      page: 1,
      list: [],
      showStart: true,
      showNull: false,
      nodeId: "",
      level: "",
      noticeInfo: {},
      allAccountTotalUSDTAmount: 0,
      loading1: false,
      profit: {},
      hasBeenCalled: false, //判断是否触发过渲染完成函数
      showLoading: true,
      showActivity: 1,
      drawCount: 1,
      swiper:null,
    };
  },
  beforeDestroy() {
    // 移除事件监听器
    if(this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  },
  mounted() {
    if(localStorage.getItem("keyTime")){
      if((new Date().getTime())>JSON.parse(localStorage.getItem('keyTime')).time){
        localStorage.removeItem("keyTime");//清除本地缓存
        localStorage.setItem("noticeId",'');
      }
    }
    // localStorage.getItem('showActivity') == null ? localStorage.setItem('showActivity', 0) : this.showActivity = localStorage.getItem('showActivity');
    // bus.on("closeActivity", (e) => {
    //     this.showActivity=1;
    //   });
    //this.onLoad();
    this.handleInit();
    setTimeout(()=>{
      if(localStorage.getItem('ismobile') == 1) {
        this.initSwiper()
      }
    },2000)
    
  },

  //计算属性
  computed: {
    showTransferTip:function(){
      let result = false;
      if(!this.showSuccess&&!this.showNotice){
        result = this.$store.state.hvUserShow;
      }
      return result
    }
  },
  methods: {
    initSwiper(){
      this.swiper = new Swiper(".swiper-container", {
        slidesPerView: 1,
        spaceBetween: 20,
        loop: false,
        autoplay : 3000,
      });
    },
    handlerBlind() {
      this.showBilnd = false;
      this.$router.push('/blind')
    },
    handlerLink() {
      window.open(' https://daot.game?provider=4', '_blank');
      return;
    },
    async handleInit() {
      try {
        if(localStorage.getItem("token") != null) {
          setTimeout(async ()=>{
            const overview = await this.$post(this.URL.assets.overview, {
              accountType: 1,
            });
            if (overview.code == 0) {
              this.totalsBalance = overview.data.allAccountTotalUSDTAmount;
            }
          })
          setTimeout(async ()=>{
            if (localStorage.getItem("noticeId") > 0) {
              // await this.getrevice();
            }
          })
          //判断是否属于首次弹出
          // setTimeout(async ()=>{
          //   this.$store.commit("SET_BINDINVITED", !this.$store.state.userData.hadInvited);
          // })
        }
        setTimeout(async()=>{
          const galactic = await this.$post(this.URL.home.galactic, {});
          if (galactic.code == 0) {
            this.startList = galactic.data;
            this.startTypeId = galactic.data[0].id;
            if (window.screen.width > 767) {
              this.showInfo = true;
            } else {
              this.showList = true;
              this.showLoading = false;
            }
          }
        })
        setTimeout(async()=>{
          //获取新的新闻公告
          const notice = await this.$post(this.URL.home.newNotice, {});
          if (notice.code == 0) {
            if (localStorage.getItem("noticeId") != notice.data[0].id) {
              this.showNotice = true;
              this.noticeInfo = notice.data[0];
              let datea = this.common.dateBJtoLocal(this.noticeInfo.date), time1 = datea.getTime(), time2 = 4 * 60 * 60 * 1000;
              this.noticeInfo.datetime = this.common.formatDate(time1 + time2);
            } else {
              this.showInte = true;
            }
          }
        })
        
      } catch (error) {
        if (window.screen.width <= 767) {
          this.showLoading = false;
        }
      }
    },
    handlerActivity(type) {
      if (localStorage.getItem('showActivity') == 0) {
        if (type == 1) {
          this.showActivity = 1;
          bus.emit('handlerShow', false)
        } else {
          this.showActivity = 0;
          bus.emit('handlerShow', true)
        }

      }
    },
    //判断星球渲染完成
    handleRenderEnd() {
      if (this.hasBeenCalled) return;
      this.showLoading = false;
    },
    //收益弹窗
    async getrevice() {
      const res = await this.$post(this.URL.market.profitlist, {});
      if (res.code == 0) {
        this.profit = res.data;
        let total =
          parseFloat(res.data.landInComeDu) +
          parseFloat(res.data.landInComeRc) +
          parseFloat(res.data.landTaxationDu) +
          parseFloat(res.data.algebraRewardRc) +
          parseFloat(res.data.recommendDu) +
          parseFloat(res.data.integralDp);
        if (total > 0) {
          this.showSuccess = true;
        } else {
          this.showSuccess = false;
        }
      }
    },
    getBlind() {
      this.$post(this.URL.blind.check, {}).then(res => {
        if (res.code == 0) {
          // this.showBilnd = true;
          this.getCount()
        }
      })
    },
    async getCount() {
      let info = await this.$post(this.URL.blind.config, {});
      console.log(info.data.drawCount)
      if (info.code == 0) {
        this.drawCount = info.data.drawCount;
        this.showBilnd = true;
      }
    },
    handlerRevice() {
      if (this.loading1) {
        return;
      }
      this.loading1 = true;
      this.$post(this.URL.market.withdraw, {}).then((res) => {

        if (res.code == 0) {
          this.showSuccess = false;
          let msg = this.$message.success(this.$t("market.desc128"));
          setTimeout(() => {
            msg.close();
            this.$store.dispath("togetherReceiveAfter");
          }, 1000);
          //this.getBlind();
        } else {
          this.loading1 = false;
          this.$message.error(res.message);
        }
      });
    },
    handlerCloseShowTransferTip(t) {
      this.showTransferTip = false;
      this.$store.commit('SET_HVUSERSHOW',false);
      if(t==1){
        //跳转至迁移账户
        bus.emit('hadleropen',3)
      }
    },
    //计时
    setNoticedInfo(){
      localStorage.setItem("noticeId", this.noticeInfo.id);
      localStorage.setItem("keyTime",JSON.stringify({time:new Date().getTime()+(24*60*60*1000)}))  //保存时长
      this.showNotice = false;
    },

    handlerCloseNotice() {
      this.setNoticedInfo();
      this.showInte = true;
    },
    handlerInfoNotice() {
      this.setNoticedInfo();
      window.location.href = document.location.origin + this.noticeInfo.forwardPath;
    },
    handlerClose() {
      this.showDetail = false;
    },
    handlerInfo(info) {
      this.nodeId = info.id;
      this.level = info.typeId;
      this.showDetail = true;
    },
    onLoad() {
      this.$post(this.URL.star.list, {
        planetGalacticId: this.type == 0 ? "" : this.type,
        track: this.track,
        no: this.no,
      }).then((res) => {
        if (res.code == 0) {
          this.list = res.data;
          this.showStart = false;
          if (this.list.length == 0) {
            this.showNull = true;
          } else {
            this.showNull = false;
          }
        }
      });
    },

    handlerSearch() {
      if (this.type == 0 && this.track == "" && this.no == "") {
        this.showStart = true;
      } else {
        this.onLoad();
      }
    },
    getlist() {
      this.$post(this.URL.home.galactic, {
        id: this.type,
        track: this.track,
        no: this.no,
      }).then((res) => {
        if (res.code == 0) {
          this.startList = res.data;
        }
      });
    },
    getMoney() {
      this.$post(this.URL.assets.info, {
        accountType: 1,
      }).then((res) => {
        if (res.code == 0) {
          this.totalsBalance = res.data.totalUSDTBalance;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handlerType1(id) {
      this.showInfo = false;
      this.startTypeId = id;
      this.showList = false;
      this.hasBeenCalled = false;
      this.showLoading = true;
      setTimeout(() => {
        this.showInfo = true;
      }, 500);
    },
    handlerType(id) {
      if (id != this.startTypeId) {
        this.hasBeenCalled = false;
        this.showLoading = true;
        this.showInfo = false;
        this.startTypeId = id;
        setTimeout(() => {
          this.showInfo = true;
        }, 100);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.modal-con1 {
  position: relative;
  padding: 32px;
  width: 480px;

  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal-title {
    margin-bottom: 32px;
    font-size: 20px;
    color: #ffffff;
  }

  .modal-list {
    padding: 20px;
    background: #242424;
    border-radius: 8px;

    .list-info {
      font-size: 16px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 20px;

      div {
        flex: 1;

        img {
          width: 12px;
          height: 12px;
          margin: 2px 0 0 4px;
        }
      }

      p {
        text-align: right;
        flex: 1;
        color: #fff;

        span {
          width: 100%;
          display: inline-table;
        }
      }

      .speed {
        width: max-content;
        margin-top: 12px;
        border-radius: 6px;
        background: rgba(201, 250, 91, 0.10);
        font-size: 13px;
        color: #C9FA5B;
        padding: 4px;
        line-height: 14px;

        img {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .modal-bgbtn {
    width: 100%;
    height: 50px;
    margin-top: 32px;
    background: url("../../public/images/land/btnbg16.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #000000;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
  }

  .modal-tips {
    margin-top: 20px;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.6);
  }
}

.new-home {
  .start-type {
    position: fixed;
    top: 127px;
    left: 50%;
    width: 70vw;
    z-index: 56;
    transform: translateX(-50%);
    justify-content: center;
  }

  .topact {
    top: 179px;
  }

  .start-type div {
    flex: 0 0 170px;
    height: 44px;
    background: url("../../public/images/new/typebg.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 14px;
    color: #ffffff;
    margin-left: 10px;
    cursor: pointer;
  }

  .start-type div:first-child {
    margin-left: 0;
  }

  .startactbg {
    background: url("../../public/images/new/typebg1.png") center no-repeat !important;
    background-size: 100% 100%;
    color: #000000 !important;
  }
}

.blind-con {
  width: 520px;
  padding-bottom: 32px;
  background: url('../../public/images/bilnd/blindbg.png') center no-repeat;
  background-size: 100% 100%;

  .blind-logo {
    padding: 32px 32px 38px;
    justify-content: space-between;

    .img1 {
      flex: 0 0 198px;
      width: 198px;
      height: 30px;
    }

    .img2 {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      margin: 3px 0 0;
      cursor: pointer;
    }
  }

  .blind-tips {
    padding: 0 32px;
    text-align: center;
    font-size: 20px;
    color: #FFFFFF;
    font-weight: 600;
  }

  .blind-img {
    margin: 20px 0;
    text-align: center;

    img {
      width: 160px;
      height: 160px;
    }
  }

  .blind-title {
    padding: 0 83px;
    text-align: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  .blind-btn {
    width: 418px;
    height: 52px;
    margin: 20px auto 0;
    background: url('../../public/images/bilnd/btnbg.png') center no-repeat;
    background-size: 100% 100%;
    font-size: 18px;
    color: #000000;
    cursor: pointer;
  }
}

::v-deep .van-popup {
  border-radius: 20px;
  overflow-y: initial;
}

.showTranfer{
  position: relative;
  width: 500px;
  padding: 40px 0;
  max-height: 653px;

  .close {
    position: absolute;
    bottom: -58px;
    left: 50%;
    width: 38px;
    height: 38px;
    transform: translateX(-50%);
    cursor: pointer;
  }

  .notice-name {
    padding: 0 40px 0;
    margin-bottom: 32px;
    font-size: 24px;
    color: #c9fa5b;
    line-height: 24px;
    text-align: center;
  }

  .notice-title {
    padding: 0 40px 12px;
    font-size: 18px;
    color: #ffffff;
  }

  .notice-desc {
    max-height: 263px;
    overflow-y: scroll;
    padding: 0 40px;
    font-size: 16px;
    color: #fff;
    text-align: center;

    &::-webkit-scrollbar {
      width: 0px; //修改滚动条宽度
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #666666;
    }
  }
  .notice-flx-btn{
    display: flex;

  }
  .notice-btn {
    margin: 23px auto 0;
    width: 160px;
    height: 42px;
    background: url("../../public/images/new/btnbg28.png") center no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    font-size: 18px;
    color: #000000;
    text-align: center;
    line-height: 42px;
  }
  .notice-btn-cancel{
    background: url("../../public/images/new/btnbg24.png") center no-repeat;
    background-size: 100% 100%;
  }
}

.notice-con {
  position: relative;
  width: 500px;
  padding: 40px 0px;
  max-height: 653px;
  background: url("../../public/images/new/noticebg.png") top no-repeat;
  background-size: 100% 653px;

  .close {
    position: absolute;
    bottom: -58px;
    left: 50%;
    width: 38px;
    height: 38px;
    transform: translateX(-50%);
    cursor: pointer;
  }

  .notice-name {
    padding: 20px 40px 0;
    margin-bottom: 32px;
    font-size: 24px;
    color: #c9fa5b;
    line-height: 24px;
  }

  .notice-title {
    padding: 0 40px 12px;
    font-size: 18px;
    color: #ffffff;
  }

  .notice-desc {
    max-height: 263px;
    overflow-y: scroll;
    padding: 0 40px;
    font-size: 16px;
    color: #fff;

    &::-webkit-scrollbar {
      width: 0px; //修改滚动条宽度
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #666666;
    }
  }

  .notice-time {
    padding: 23px 40px 0;
    font-size: 16px;
    text-align: right;
    color: rgba(255, 255, 255, 0.6);
  }

  .notice-btn {
    margin: 23px auto 0;
    width: 418px;
    height: 52px;
    background: url("../../public/images/new/btnbg25.png") center no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    font-size: 18px;
    color: #000000;
    text-align: center;
    line-height: 52px;
  }
}

.h5-con {
  display: none;
}

.no-data {
  width: 100%;
  padding: 50px 0;

  img {
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto 16px;
  }

  span {
    display: block;
    margin: 0 auto;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #a1a1a1;
  }
}

@media (max-width: 1200px) {
  .new-home {
    .start-type {
      width: 80%;
      top: 80px;
    }

    .start-type div {
      flex: 0 0 140px;
    }
  }
}

@media (max-width: 767px) {
  .modal-con1 {
    width: 100%;
    padding: 20px;

    .close {
      top: 20px;
      right: 20px;
    }

    .modal-list {
      padding: 12px;
    }
  }

  .blind-con {
    width: calc(100vw - 8px);
    height: fit-content;
    padding-bottom: 20px;

    .blind-logo {
      padding: 20px;

      .img1 {
        flex: 0 0 158px;
        width: 158px;
        height: 24px;
      }

      .img2 {
        flex: 0 0 14px;
        width: 14px;
        height: 14px;
        margin-top: 5px;
      }
    }

    .blind-tips {
      font-size: 16px;
    }

    .blind-title {
      font-size: 12px;
      padding: 0 24px;
    }

    .blind-btn {
      width: calc(100vw - 24px);
      height: 42px;
      font-size: 16px;
    }
  }

  .start-type {
    display: none;
  }

  .h5-con {
    display: block;
    padding: 56px 16px 100px;

    .cont-top {
      position: fixed;
      top: 44px;
      left: 50%;
      width: calc(100vw - 8px);
      z-index: 22;
      padding-top: 12px;
      background: #000;
      transform: translateX(-50%);
    }
    .banner-daobet{
      width: 100%;
      max-height: 240px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .con-assets {
      padding: 20px 16px;
      border-radius: 8px;
      background: #181818;

      .assets-name {
        font-size: 14px;
        color: #ffffff;
        line-height: 14px;

        img {
          width: 10px;
          height: 10px;
          margin: 2px 2px 0 0;
        }
      }

      p {
        font-size: 24px;
        color: #c9fa5b;
        margin-top: 12px;
      }
    }

    .home-notice {
      margin: 12px 0 0;

      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }

      div {
        flex: 1;
        overflow: hidden;
        font-size: 13px;
        color: #ffffff;
        line-height: 18px;
      }
    }

    .home-select {
      margin-top: 20px;
      margin-bottom: 15px;
      flex-wrap: wrap;

      .select-info {
        flex: 0 0 30%;

        &:nth-child(2) {
          flex: 1;
        }

        .name {
          margin-bottom: 8px;
          font-size: 12px;
          color: #b9b9b9;
        }

        p {
          input {
            flex: 0 0 49%;
            width: 49%;
            height: 34px;
            font-size: 14px;
            color: #fff;
            border: 1px solid rgba(255, 255, 255, 0.2);
            box-sizing: border-box;
            padding: 0 12px;

            &:last-child {
              margin-left: 2%;
            }
          }
        }

        ::v-deep .el-select {
          width: 90%;

          .el-input__wrapper {
            background-color: rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
            height: 34px;

            .el-input__inner {
              color: #fff;
              font-size: 14px;
            }
          }

          .is-focus {
            box-shadow: 0 0 0 1px rgba(210, 251, 113, 1) inset !important;
          }
        }
      }

      .select-ttn {
        flex: 0 0 80px;
        height: 34px;
        margin: 24px 0 0 10px;
        background: url("../../public/images/new/btnbg1.png") center no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        color: #000;
      }
    }

    .home-start,
    .home-list {
      padding-top: 300px;
    }

    .start-list {
      padding: 20px;
      margin-bottom: 10px;
      background: url("../../public/images/new/homebg.png") center no-repeat;
      background-size: 100% 100%;
      border-radius: 8px;
      background-color: #181818;

      img {
        flex: 0 0 124px;
        width: 124px;
        height: 124px;
        margin-right: 16px;
        object-fit: cover;
      }

      .img-star {
        padding: 20px 0;
        flex: 1;
        width: auto;
        height: 144px;
        margin-right: 0;
      }

      .list-info {
        flex: 1;

        .info-name {
          font-size: 16px;
          color: #ffffff;
          margin-bottom: 16px;
        }

        .info-desc {
          font-size: 13px;
          margin-bottom: 10px;
          line-height: 13px;
          color: rgba(255, 255, 255, 0.6);

          span {
            color: #fff;
          }
        }
      }
    }

    .list-info {
      padding: 20px 16px;
      margin-bottom: 10px;
      border-radius: 8px;
      background: #181818;

      img {
        flex: 0 0 110px;
        width: 110px;
        height: 110px;
        margin-right: 16px;
        border-radius: 50%;
        object-fit: cover;
      }

      .info {
        flex: 1;

        .info-name {
          font-size: 16px;
          color: #ffffff;
          margin-bottom: 16px;
          line-height: 16px;
        }

        .info-price {
          flex-wrap: wrap;

          .price {
            flex: 0 0 50%;
            font-size: 12px;
            color: #ffffff;
            line-height: 14px;

            &:nth-child(-n + 2) {
              margin-bottom: 16px;
            }

            p {
              margin-top: 8px;
              color: #a8a8a8;
            }
          }
        }

        .planet-btn {
          margin-top: 16px;
          width: 89px;
          height: 23px;
          background: url("../../public/images/new/btnbg.png") center no-repeat;
          background-size: 100% 100%;
          text-align: center;
          font-size: 14px;
          color: #000000;
          line-height: 23px;
        }
      }
    }
  }
  .showTranfer{
    width: 298px;
    padding: 32px 0 20px;
    .close {
      bottom: -40px;
      width: 28px;
      height: 28px;
    }

    .notice-name {
      padding: 0 16px;
      margin-bottom: 12px;
      font-size: 16px;
    }

    .notice-title {
      padding: 0 16px 8px;
      font-size: 14px;
    }

    .notice-desc {
      padding: 0 16px;
      font-size: 14px;
      max-height: 199px !important;
    }

    .notice-btn {
      width: 120px;
      height: 40px;
      margin: 12px auto 0;
      font-size: 14px;
      line-height: 38px;
    }

  }

  .notice-con {
    width: 298px;
    padding: 49px 0 20px;
    background: url("../../public/images/new/noticebg1.png") top no-repeat;
    background-size: 100% 379px;

    .close {
      bottom: -40px;
      width: 28px;
      height: 28px;
    }

    .notice-name {
      padding: 0 16px;
      margin-bottom: 12px;
      font-size: 16px;
    }

    .notice-title {
      padding: 0 16px 8px;
      font-size: 14px;
    }

    .notice-desc {
      padding: 0 16px;
      font-size: 14px;
      max-height: 199px !important;
    }

    .notice-time {
      padding: 23px 16px 0;
      font-size: 14px;
    }

    .notice-btn {
      width: 262px;
      height: 40px;
      margin: 12px auto 0;
      background: url("../../public/images/new/btnbg26.png") top no-repeat;
      background-size: 100% 100%;
      font-size: 14px;
      line-height: 38px;
    }
  }
}</style>
